:root {
  --headerMinSize: 20px;
  --headerMaxSize: 50px;
  --maxWidth: 1300px;
  --color0: #303730;
  --color1: #576057;
  --color2: #7a887a;
  --color3: #97b497;
  --color4: #dae7da;
  --color5: #f9fff9;
  --minHomeHeight: 480px;
  --technicalPageMinHeight: 550px;
  --experiencePageMinHeight: 470px;
}

@font-face {
  font-family: 'AnticSlab';
  src: url('../public/AnticSlab-Regular.woff2') format('woff2'),
      url('../public/AnticSlab-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GeorgiaPro';
  src: url('../public/GeorgiaPro-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

.App{
  background: linear-gradient(0deg, rgba(249,255,249,1) 85%, rgba(87,96,87,1) 100%);
}

.App-gradient{
  background: linear-gradient(180deg, var(--color5) 20%, var(--color2) 100%);
}

.App-black{
  background: var(--color0);
  background: linear-gradient(329deg, var(--color0) 5%, var(--color1) 70%, var(--color2) 100%);
}

body{
  margin: 0;
  min-width: 390px;
  font-family: "GeorgiaPro";
}

a {
  text-decoration: none;
}

a:link {
  color: var(--color1);
}

a:visited {
  color: var(--color1);
}

a:hover {
  color: var(--color4);
}

a:active{
  color: var(--color5);
}

.indenter{
  text-indent: 400px;
}

.pages{
  max-width: var(--maxWidth);
  padding: 0px 0px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
}


.header-menu-holder{
  top:0;
  z-index: 3;
  position: sticky;
  display: flex;
  background-color: var(--color0);
  width: 100%;
}

.main-header{
  background-color: var(--color0);
  margin: 0 auto;
  padding: 10px 1vw;
  display: flex;
  justify-content: space-between;
  height: 6vh;
  width: 100%;
  min-height: var(--headerMinSize);
  max-height: var(--headerMaxSize);
}

.main-header-short{
  background-color: var(--color0);
  padding: 10px 1%;
  display: flex;
  justify-content: space-between;
  height: 6vh;
  width: 73%;
  min-height: var(--headerMinSize);
  max-height: var(--headerMaxSize);
  float: left;
}

.header-menu-holder-G{
  top:0;
  z-index: 3;
  position: sticky;
  display: flex;
  background-color: var(--color0);
  width: 100%;
}

.main-header-G{
  background-color: var(--color0);
  margin: 0 auto;
  padding: 10px max(1vw,15px);
  display: flex;
  justify-content: space-between;
  height: 6vh;
  width: 100%;
  min-height: var(--headerMinSize);
  max-height: var(--headerMaxSize);
}

.main-header-short-G{
  background-color: var(--color0);
  padding: 10px 1%;
  display: flex;
  justify-content: space-between;
  height: 6vh;
  width: 73%;
  min-height: var(--headerMinSize);
  max-height: var(--headerMaxSize);
  float: left;
}


.main-header div{
  color: var(--color5);
  font-size: max(var(--headerMinSize), min(5.5vh, calc(var(--headerMaxSize) - 5px)));
}

.main-header-short div{
  color: var(--color5);
  font-size: max(var(--headerMinSize), min(5.5vh, calc(var(--headerMaxSize) - 5px)));
}

.main-header-G div{
  color: var(--color5);
  font-size: max(var(--headerMinSize), min(5.5vh, calc(var(--headerMaxSize) - 5px)));
}

.main-header-short-G div{
  color: var(--color5);
  font-size: max(var(--headerMinSize), min(5.5vh, calc(var(--headerMaxSize) - 5px)));
}

.menu-ext{
  height: 100vh;
  width: 25%;
  background-color:var(--color2);
  z-index: 10;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
}

.menu-ext-full{
  height: 100vh;
  width: 100%;
  background-color: var(--color2);
  z-index: 10;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
}

.menu-ext-close{
  height: 6vh;
  font-size: max(var(--headerMinSize), min(5.5vh, calc(var(--headerMaxSize) - 5px)));
  margin: 0 auto;
  color: var(--color5);
  padding: 10px 0;
}

.menu-ext-current {
  height: 15%;
  font-size: max(var(--headerMinSize), min(5.5vh, calc(var(--headerMaxSize) - 5px)));
  margin: 10% auto;
  color: var(--color5);
}

.menu-ext-options {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: auto 0;
  margin-bottom: 15%;
}

.menu-ext-option{
  height: 20%;
  font-size: max(var(--headerMinSize), min(5.5vh, calc(var(--headerMaxSize) - 5px)));
  margin: max(2vh, 5px) auto;
  text-align: center;
  color: var(--color5);
  text-decoration: none;
}


.menuButton{
  overflow: visible;
  height: 100%;
}

.home{
  overflow-x: hidden;
  overflow-y:hidden;
}

.home-video{
  height: 94vh;
  overflow-y: hidden;
  position: absolute;
  z-index: -1;
  width: 100vw;
  max-width: var(--maxWidth);
}

.home-text{
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content:last baseline;
}

.home-profile{
  height:  min(45vw,45vh);
  display: flex;
  flex-direction: row;
  justify-content:space-around;
}

.home-profile-photo{
  height:  min(45vw,45vh);
  width:  min(45vw,45vh);
  display: flex;
  max-width: calc(var(--maxWidth) * 0.45);
}


.home-profile-photo img{
  height: min(45vw,45vh);
  width: min(45vw,45vh);
  margin: auto;
}

.home-profile-name{
  height:  min(45vw,45vh);
  width:  min(45vw,45vh);
  max-width: calc(var(--maxWidth) * 0.45);
  display: flex;
  font-weight: 400;
}

.home-profile-name p{
  font-size: min(14vw,14vh);
  text-align: center;
  max-width: calc(var(--maxWidth) * 0.45);
  margin: auto;
}

.home-tagline{
  height: 5vh;
  display: flex;
}

.home-tagline p{
  font-size: calc(2vh + 1vw);
  margin: auto;
  font-weight: 500;
  color: var(--color0);
}

.home-buttons{
  height: 36vh;
  width: min(90vw, var(--maxWidth));
  display: flex;
  flex-direction: column;
  z-index: 1;
  justify-content: space-around;
  margin: 7vh 0;
}


.home-buttons span{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.home-button{
  height: 15vh;
  width: min(34vw, calc(0.26*var(--maxWidth)));
  min-width: 100px;
  background: radial-gradient(circle, var(--color0) 60%, var(--color1) 100%);
  z-index: 2;
  opacity: 0.85;
  max-width: calc(var(--maxWidth) * 0.45);
  display: flex;
  border-radius: 25px;
}

.home-button a:visited{
  color: var(--color2);
}

.home-button a:link{
  color: var(--color2);
}

.home-button a:hover{
  color: var(--color4);
}

.home-button a:active{
  color: var(--color5);
}


.home-button a {
  text-align: center;
  margin: auto;
}

.home-button-text{
  font-size: min(6vh,6vw);
  text-align: center;
  margin: auto;
}



.technical-overview{
  height: 85vh;
  min-height: calc(var(--technicalPageMinHeight) * 0.85);
  width: 100%;
  background-color: var(--color5);
  display: flex;
  flex-direction: column;

}

.technical-overview-wide{
  height: 85vh;
  min-height: calc(var(--technicalPageMinHeight) * 0.85);
  width: 100%;
  background-color: var(--color3);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.banner-statement{
  height: 5vh;
  font-size: max(5vh, 34px);
  padding: 0 2.5%;
  padding-top: max(2vh, 4px);
  padding-bottom: max(2vh, 20px);
  min-height: calc(var(--technicalPageMinHeight) * 0.05);
  display: flex;
  flex-direction: row;
  background-color: var(--color2);
  font-weight: bold;
  
}

.banner-statement-short{
  height: 12vh;
  font-size: max(5vh, 29px);
  padding: max(1vh, 5px) 2.5%;
  background-color: var(--color2);
  min-height: calc(var(--technicalPageMinHeight) * 0.12);
  text-align: center;
  font-weight: bold;
}



.banner-text{
  padding-right: calc(max(5vh, 38px) / 5);
  
}

.banner-text-short{
  padding-right: calc(max(3vh, 22.8px) / 5);
}

.skill-icons{
  width: 95%;
  height: 30vh;
  padding: max(2vh,15px) 2.5%;
  background-color:var(--color5);
  min-height: calc(var(--technicalPageMinHeight) * 0.30);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  overflow: hidden;
}

.overview-text{
  width: 95%;
  height: 36vh;
  padding: max(2vh, calc(var(--technicalPageMinHeight) * 0.02)) 2.5%;
  background-color: var(--color3);
  min-height: calc(var(--technicalPageMinHeight) * 0.36);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.overview-text p{
  font-size: max(calc(8px + 0.22vh), min(calc(5px + 0.6vw + 0.4vh), calc(10px + 0.8vh)));;
}

.big-skill{
  height: 75%;
  background-color: var(--color5);
  margin:0 auto;
}

.big-skill-holder{
  width: max(min(30vh, 30vw), calc(var(--technicalPageMinHeight) * 0.23));
  height: 30vh;
  max-height: 30vh;
  background-color: var(--color5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
}

.big-skill-label{
  height: 15%;
  padding: 2.5%;
  font-size: max(2vh, 15.2px);
  background-color:var(--color3);
  min-height: calc(var(--technicalPageMinHeight) * 0.05);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto 0;
}

.big-skill-label span{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.big-skill-label-short{
  height: 15%;
  padding: 1.25%;
  font-size: max(1.5vh, 11.4px);
  background-color:var(--color3);
  min-height: calc(var(--technicalPageMinHeight) * 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.big-skill-label div{
  margin: auto 0;
}

.big-skill span{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}

.little-skills{
  background-color: aquamarine;
  display: flex;
  width: 50vw;
  flex-direction: column;
  background-color: var(--color5);
}

.little-skills-upper{
  height: 15vh;
  background-color: var(--color5);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-height: calc(var(--technicalPageMinHeight) * 0.15);
}

.little-skills-lower{
  height: 15vh;
  background-color: var(--color5);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-height: calc(var(--technicalPageMinHeight) * 0.15);
}

.little-skill{
  height: max(min(14vh, 22vw), calc(var(--technicalPageMinHeight) * 0.1));
  width: max(min(14vh, 22vw), calc(var(--technicalPageMinHeight) * 0.1));
  margin: auto;
  background-color: var(--color5);
}

.skill-arrow {
  width: calc(10vw - 20px);
  height: max(min(30vh, 30vw), 176px);
  margin: auto 0;
  z-index: 2;
}

.big-skill-text {
  width: 95%;
  padding:0 2.5%;
  background-color: var(--color3);
  display: flex;
  flex-direction: row;
}

.big-skill-wide{
  width: max(min(55vh, 40vw), calc(var(--technicalPageMinHeight) * 0.42));
  height: max(min(55vh, 40vw), calc(var(--technicalPageMinHeight) * 0.42));
  margin:auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.big-skill-holder-wide{
  height: 70%;
}

.banner-statement-wide{
  height: 5vh;
  background-color:var(--color5);
  min-height: calc(var(--technicalPageMinHeight) * 0.05);
  
}

.big-skill-label-wide{
  height: 10%;
  font-size: max(3.5vh, 26.6px);
  padding: max(2vh, 15px) 0;
  margin: 0 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--color2);
  
}

.big-skill-label-wide span{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.big-skill-label-wide div{
  margin: auto 0;
  
}

.overview-text-wide{
  width: 50vw;
  height: max(min(55vh, 40vw), calc(var(--technicalPageMinHeight) * 0.42));
  background-color: var(--color3);
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.overview-text-wide h1{
  font-size: max(min(4vw,6vh),34.8px);
  background-color: var(--color2);
  padding: 1vh 0;
  margin: 0;
  text-align: center;
}

.overview-text-wide p{
  font-size: calc(4px + 0.8vw + 0.1vh);
  font-family: "GeorgiaPro";
}

.learn-stack-develop-holder-wide{
  height: 25%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.learn-stack-develop-holder{
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.learn-stack-develop{
  height: 100%;
  width: 30%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.learn-stack-develop-img{
  height: 70%;
}

.learn-stack-develop-tag{
  height: 20%;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  color: var(--color1);
}

.small-skill-bar{
  height: max(11vh,calc(var(--technicalPageMinHeight) * 0.11));
  padding: max(1vh,calc(var(--technicalPageMinHeight) * 0.01)) 2.5%;
  background-color: var(--color3);
  display: flex;
  width: 95%;
  overflow: hidden;
}

.small-skill{
  margin: auto;
  height: max(11vh, calc(var(--technicalPageMinHeight) * 0.11));
  width: max(11vh, calc(var(--technicalPageMinHeight) * 0.11));

}

.skill{
  height: 75%;
  width: 75%;
  z-index: 1;
  margin: 12.5%;
}

.small-skill-arrow{
  margin: auto;
  height: max(10vh, calc(var(--technicalPageMinHeight) * 0.10));
  width: max(10vh, calc(var(--technicalPageMinHeight) * 0.10));
  padding: 0.5vh 0px;
  z-index: 2;
}

.technical-down{
  height: max(calc(8vh - 60px), 5px);
  min-height: max(calc(calc(var(--technicalPageMinHeight) * 0.09) - 60px),5px);
  width: 100%;
  padding: 1vh 0px;
}

.technical-page{
  height: 85vh;
  min-height: calc(var(--technicalPageMinHeight) * 0.85);
  width: 100%;
  background-color: var(--color3);
  display: flex;
  flex-direction: column;
}

.technical-page-banner{
  height: 12vh;
  min-height: calc(var(--technicalPageMinHeight) * 0.12);
  background-color: var(--color2);
  font-size: max(min(5vh, 5.5vw), 38px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2vw;
}

.technical-page-banner-short{
  height: 12vh;
  min-height: calc(var(--technicalPageMinHeight) * 0.12);
  background-color: var(--color2);
  font-size: max(min(5vh, 5.5vw), 26px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2vw;
}

.technical-page-content{
  height: 69vh;
  min-height: calc(var(--technicalPageMinHeight) * 0.69);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2vh 0;
  background-color: var(--color3);
}

.technical-page-content-column{
  display: flex;
  flex-direction: column;
  height: 69vh;
  width: 100%;
  margin: 0 1vw;
  justify-content: space-between;
  min-height: calc(var(--technicalPageMinHeight) * 0.69);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}

.technical-page-selector-content{
  height: 81vh;
  min-height: calc(var(--technicalPageMinHeight) * 0.81);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2vh 0;
  background-color: var(--color3);
}


.technical-page-selector-content-column{
  display: flex;
  flex-direction: column;
  height: 81vh;
  width: 100%;
  margin: 0 1vw;
  background-color: var(--color4);
  justify-content: space-between;
  min-height: calc(var(--technicalPageMinHeight) * 0.81);
}

.technical-page-selector-content-empty{
  display: flex;
  flex-direction: column;
  height: 81vh;
  width: 100%;
  margin: 0 1vw;
  justify-content: space-between;
  min-height: calc(var(--technicalPageMinHeight) * 0.81);
}

.technical-page-selector-content-option{
  height: 100%;
  width: 80%;
  margin: 3vh 10%;
  background-color: var(--color2);
  text-align: center;
  font-size: max(min(4vh, 4.4vw), 30.4px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*
  //plus both vh and vw, make vh plus min of max height
  Old font sizing:
  font-size: calc(6.4px + min(0.35vw, calc(0.005*var(--maxWidth))) + max(0.5vh, calc(0.005 * 650px)));
*/
.technical-page-content-column .MERN{
  padding: 2%;
  font-size: max(calc(8px + 0.22vh), min(calc(4.7px + 0.6vw + 0.4vh), calc(9.4px + 0.8vh)));
  line-height: 125%;
  font-weight: 580;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: var(--color4);
}

.technical-page-content-column .C{
  padding: 2%;
  font-size: max(calc(8px + 0.22vh), min(calc(5px + 0.6vw + 0.4vh), calc(10px + 0.8vh)));
  line-height: 125%;
  font-weight: 580;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 98.5%;
  background-color: var(--color4);
}

#C{
  padding: 2%;
  font-size: max(calc(8px + 0.22vh), min(calc(5px + 0.6vw + 0.4vh), calc(10px + 0.8vh)));
  line-height: 125%;
  font-weight: 580;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  background-color: var(--color4);
}
/* 
OLD FS: calc(6.4px + min(0.35vw, calc(0.005*var(--maxWidth))) + max(0.5vh, calc(0.005 * 650px)))
*/
.technical-page-content-column .Desktop{
  padding: 2%;
  font-size: max(calc(8px + 0.22vh), min(calc(4.8px + 0.6vw + 0.4vh), calc(10px + 0.8vh)));
  line-height: 125%;
  font-weight: 580;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: var(--color4);
}


.technical-page-selector-content-column .Java{
  padding: 2%;
  font-size: max(calc(7px + 0.22vh), min(calc(3.6px + 0.6vw + 0.4vh), calc(9.6px + 0.8vh)));
  line-height: 125%;
  font-weight: 580;
}

.technical-page-selector-content-column .SQL{
  padding: 2%;
  font-size: max(calc(7px + 0.22vh), min(calc(4px + 0.6vw + 0.4vh), calc(10px + 0.8vh)));
  line-height: 125%;
  font-weight: 580;
}

.technical-page-selector-content-column .Python{
  padding: 2%;
  font-size: max(calc(7px + 0.22vh), min(calc(4px + 0.6vw + 0.4vh), calc(10px + 0.8vh)));
  line-height: 125%;
  font-weight: 580;
}


.C-img-holder span{
  width: 95%;
  height: 75%;
  padding: 0.75vh 2.5%;
  font-size: max(min(2.5vh, 2.75vw), 19px);
  display:flex;
  flex-wrap: wrap;
  flex-direction: column;

}

.technical-page-content-column .C-img-holder{
  width: 100%;
  margin: 2.5vh, 0;
  height: 50vh;
  min-height: calc(var(--technicalPageMinHeight) * 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.technical-page-content-column .C-img{
  width: 90%;
  margin: auto 5%;
  max-height: 45vh;
}

.technical-page-content-column .C-img-v{
  height: 70%;
  width:max-content;
  max-width: 90%;
  margin: 2.5% 5%;
  max-height: 35vh;
  min-height: calc(var(--technicalPageMinHeight) * 0.34);
}

.technical-page-tech-used{
  height: 17.5vh;
  width: 100%;
  padding: 0.75vh 0;
  font-size: max(min(2.5vh, 2.75vw), 19px);
  display:flex;
  flex-direction: column;
  min-height: calc(var(--technicalPageMinHeight) * 0.175);
  justify-content: space-between;
  background-color: var(--color2);
}

.technical-page-selector-content-column .technical-page-tech-used{
  height: 4.5vh;
  
}

.technical-page-tech-used span{
  width: 100%;
  height: 75%;
  padding: 0.75vh 0;
  font-size: max(min(2.5vh, 2.75vw), 19px);
  display:flex;
  flex-wrap: wrap;
  flex-direction: column;

}

.technical-page-tech-used-tech{
  font-size: max(min(1.75vh, 2vw), 10px);
  padding: 0.5vh 1vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 45%;
  
}

.technical-page-image-showcase-holder{
  height: 48vh;
  width: 100%;
  min-height: calc(var(--technicalPageMinHeight) * 0.48);
  display: flex;
  flex-direction:row;
  justify-content: space-between;
}

.technical-page-image-showcase-holder-short{
  height: 48vh;
  width: 100%;
  min-height: calc(var(--technicalPageMinHeight) * 0.48);
  display: flex;
  flex-direction:column;
  justify-content: space-between;
}

.technical-page-image-showcase-holder .technical-page-image-showcase-holder-sub{
  height: 100%;
  width: 48%;
  min-height: calc(var(--technicalPageMinHeight) * 0.48);
  display: flex;
  flex-direction:row;
}

.technical-page-image-showcase-holder-short .technical-page-image-showcase-holder-sub{
  height: 49%;
  width: 100%;
  display: flex;
  flex-direction:row;
}

.image-showcase{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color1);
}

.image-showcase-image-holder{
  height: calc(90% - 2px);
  width: calc(100% - 2px);
  object-fit: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: var(--color2)
}

.image-showcase-image{
  max-width: 100%;
  max-height: 90%;
  object-fit: contain;
}

.image-showcase-scroller{
  height: 10%;
  width: 100%;
  background-color: var(--color2);
  display: flex;
  flex-direction: row;
}

.image-showcase-scroller-arrows{
  height: 100%;
  width: 15%;
  margin: 0 2.5%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.image-showcase-scroller-description{
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.image-showcase-scroller-description span{
  margin: auto 0;
  padding: 0 4%;
  font-size: max(min(2vh, 2.2vw), 11px);
}

.image-showcase-scroller-arrow{
  height: calc(100% - 2px);
  width: 40%;
  border-style: solid;
  border-width: 1px;
  border-color: var(--color1)
}

.experience {
  height:100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 2400px;
  margin-bottom: 10vh;
  overflow-x: hidden;
}

.experience-welcome{
  font-size: min(calc(48px + 4vw),130px);
  height: 28vh;
  min-height: calc(var(--experiencePageMinHeight) * 0.28);
  color: var(--color5);
  margin: 5vh auto;
  text-align: center;
  min-height: 211px;
}

.experience-welcome-arrow-holder{
  height: 40vh;
  min-height: calc(var(--experiencePageMinHeight) * 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.experience-welcome-arrow{
  height: 100%;
  stroke: var(--color5);
  stroke-width: 2;
  stroke-linecap:round;
  stroke-linejoin: round;
}

.experience-welcome-arrowhead{
  stroke-width: 2;
}

.timeline{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.timeline-line-empty{
  display: flex;
  flex-direction: row;
  height: 27vh;
  min-height: calc(var(--experiencePageMinHeight) * 0.27);
}

.timeline-line-holder{
  height: 100%;
  width: 2%;
  margin-left: 8%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.timeline-line-svg-empty{
  height: 100%;
  stroke: var(--color5);
  stroke-width: 8;
  stroke-linecap:round;
  stroke-linejoin: round;
}

.timeline-line-svg{
  height: 100%;
  stroke: var(--color5);
  stroke-width: 8;
  stroke-linecap:round;
  stroke-linejoin: round;
}

.timeline-section{
  display: flex;
  flex-direction: column;
}

.timeline-line{
  display: flex;
  flex-direction: row;
  height: 70vh;
  min-height: calc(var(--experiencePageMinHeight) * 0.7);
}

.timeline-date{
  height: 5vh;
  font-size: calc(1vw + 2vh);
  margin: auto 0;
  margin-left: 4%;
  min-height: calc(var(--experiencePageMinHeight) * 0.05);
}

.timeline-content{
  width: 75%;
  margin-left: 5%;
  margin-right: 5%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  min-height: calc(var(--experiencePageMinHeight) * 0.7);
}
/*
PREV FONT ALGO
1 calc(60px + min(4.5vw, calc(0.045*var(--maxWidth))))
2 calc(32px + min(3vw, calc(0.030*var(--maxWidth))))
3 calc(16px + min(1.8vw, calc(0.013*var(--maxWidth))))
4 calc(8px + min(2.4vw, calc(0.01*var(--maxWidth))))
5 calc(4px + min(1.3vw, calc(0.013*var(--maxWidth))))
*/

.timeline-content-1{
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size:max(calc(var(--experiencePageMinHeight) * 0.16), calc(calc(var(--experiencePageMinHeight) * 0.10) + 6vh));
  color: var(--color5);
}

.timeline-content-2{
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: max(calc(var(--experiencePageMinHeight) * 0.08),calc(calc(var(--experiencePageMinHeight) * 0.02) + 6vh));
  color: var(--color5);
}

.timeline-content-2-5{
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: max(calc(var(--experiencePageMinHeight) * 0.06),calc(calc(var(--experiencePageMinHeight) * 0.02) + 4vh));
  color: var(--color5);
}

.timeline-content-3{
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: max(calc(var(--experiencePageMinHeight) * 0.04),calc(calc(var(--experiencePageMinHeight) * 0.02) + 2vh));
  color: var(--color5);
}

.timeline-content-4{
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: max(calc(var(--experiencePageMinHeight) * 0.02),2vh);
  color: var(--color5);
}

.timeline-content-5{
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: max(calc(var(--experiencePageMinHeight) * 0.01),1vh);
  color: var(--color5);
}

.timeline-content-img-holder{
  height: 18vh;
  margin-top: 4vh;
  margin-bottom: 1vh;
  min-height: calc(var(--experiencePageMinHeight) * 0.18);
}

.timeline-content-img-holder-long{
  max-height: 8vh;
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
  min-height: calc(var(--experiencePageMinHeight) * 0.06);
}

.timeline-content-img-holder-long-shorter{
  max-height: 6vh;
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
  min-height: calc(var(--experiencePageMinHeight) * 0.06);
}

.timeline-content img{
  height: 100%;
}

.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 90vh;
  min-height: calc(var(--minHomeHeight)*0.9);
}

.about-title{
  font-size: max(20px, min(5vh,70px));
  padding-top: 7vh;
  padding-bottom: 1vh;
  padding-left: 4%;
  text-decoration: underline;
  font-weight: 700;
  height: 12vh;
}

.about-content{
  padding: 0 5%;
  font-size: max(calc(9.5px + 0.6vh), min(calc(6.1px + 0.6vw + 0.4vh), calc(15.5px + 0.4vh)));
  line-height: 125%;
  font-weight: 580;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 500;
}

.home-about-gap{
  margin: auto;
  height: 10vh;
}

